<template>
  <div class="py-24 dark:bg-gray-900 bg-white">
    <div class="md:container mx-auto dark:text-gray-300 text-black">
      <h1 class="text-4xl mb-7 text-left pl-10">Часто задаваемые вопросы и ответы (FAQ)</h1>

      <div class="flex flex-wrap sm:justify-between lg:justify-around dark:bg-gray-800 bg-white shadow-md dark:shadow-none my-10 rounded mx-5">
        <button :key="index" v-for="(item, index) in items"
              class="p-5 inline-flex items-center dark:text-gray-300 text-gray-700 hover:text-blue-500 dark:hover:text-green-300">
              {{ item.name }}
        </button>
      </div>

      <div class="flex md:flex-row gap-3 sm:flex-col px-5">
        <div class="md:w-1/5 sm:w-full md:border-r border-gray-400">
          <div class="flex md:flex-col sm:flex-row sm:flex-wrap sm:gap-4 items-start dark:bg-gray-800 bg-white py-5">
            <button :key="index" v-for="(item, index) in products" :class="{'current':productCode === item.code}"
            class="px-3 mb-2 text-left dark:text-gray-300 text-gray-700 hover:text-blue-500 dark:hover:text-green-300"
            @click="updateCode(item.code)" >
              {{item.name}}
            </button>
          </div>
        </div>

        <div class="md:w-4/5 sm:w-full flex flex-col items-start">
          <div v-for="(item, index) in errorList" :key="index" :class="{'current':state === index}"
          class="dark:bg-gray-800 bg-white p-5 dark:text-gray-300 text-gray-700 hover:text-blue-500 dark:hover:text-green-300 border-b border-gray-500 w-full  text-left" @click="updateState(index)">
            <span class="text-xl">+</span> {{item.preview}}
             <transition name="slide">
                 <div v-show="state === index" class=" dark:text-gray-300 text-gray-700 w-full text-left ">
                     <p class="py-2">{{item.detail}}</p>
                 </div>
             </transition>
          </div>
          <div v-if="errorList.length === 0" class="ml-5">Ничего не найдено</div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

import axios from 'axios'
export default {
  name: "Support",
  data(){
    return{
      items: [
        {
          name: 'ПО',
        },
        {
          name: 'Логистические услуги',
        },
        {
          name: 'Розничная торговля',
        },
        {
          name: 'Обращения',
        }
      ],
      products: [
        {
          name: `КПП “Е-Декларант” “Е-CLIENT”`,
          code: 'e-declarant'
        },
        {
          name: `АС “СЭЗ”`,
          code: 'sez'
        },
        {
          name: `АРМ “ПТО”`,
          code: 'pto'
        },
        {
          name: `АРМ “БТС Специалист”`,
          code: 'bts-specialist'
        },
        {
          name: `ПМ “Таможенный перевозчик”`,
          code: 'customs-carrier'
        },
      ],
      errorList: [],
      productCode: 'e-declarant',
      state: '',
      id: ''
    }
  },
   created() {
    this.fetch()
  },
  methods: {
    async fetch(productCode) {
      if(!productCode){
        productCode = this.productCode
      }
      axios.get(`https://declarant.by/rest/software/support/${productCode}`)
          .then((res) => this.errorList = res.data.data)  
    },
    updateCode(code){
      this.id = '';
      this.state = false,
      this.productCode = code
      this.fetch(this.productCode)
    },
    updateState(index){
        if(this.state === index){
             return this.state = ''
        }
        this.state = index
    }
  }


}
</script>

<style scoped>
.current {
  color: rgb(56, 189, 248);
}
.slide-enter-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
    max-height: 500px;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0px;
}
</style>